@import '~/styles/utils';

.link {
  height: 62rem;
  width: 62rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: white;
  border: 5rem solid transparent;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 64rem;
    height: 64rem;
    border-radius: 50%;
    background: $gradient1;
    z-index: -1;
    opacity: 0.3;
    transition: 0.3s opacity ease-out;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

.darkLink {
  color: $color-white;
  background: $color-dark-dark-blue;

  &:after {
    background: $gradient2;
  }
}
