@import '~/styles/utils';

.indicatorContainer {
  display: flex;
  gap: 10rem;
  height: 20rem;
}

.indicator {
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
  min-width: 5rem;
  position: relative;
  z-index: 2;
}

.indicatorBar {
  background-color: #{transparent-color('dark-gray', 0.2)};
  height: 5rem;
  width: 100%;
  border-radius: 2.5rem;
  position: relative;
  [data-theme='dark'] & {
    background-color: #{transparent-color('light-gray', 0.2)};
  }
}

.activeIndicator {
  background: $accent-color;
  height: 5rem;
  width: 100%;
  border-radius: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
}
