@import '~/styles/utils';

.container {
  position: absolute;
  bottom: 0;
}

.wider {
  visibility: hidden;
}
