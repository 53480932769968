@import '~/styles/utils';

.container {
  height: 300vh;
  background: $color-dark-dark-blue;
  color: $color-white;
  overflow: clip;
  z-index: 5;
  position: relative;
}

.headlineContainer {
  height: 100vh;
  display: inline-flex;
  align-items: center;
  position: sticky;
  top: 0;
}

.headline {
  @include title;
  white-space: nowrap;
  padding-right: 41rem;
  padding-left: 68rem;

  @include landscape {
    font-size: 170rem;
    padding-right: 228rem;
    padding-left: 293rem;
  }
}

.gridContainer {
  display: none;

  @include landscape {
    position: absolute;
    left: 100%;
    height: 100%;
    width: 100vw;
    top: 0;
    display: inline-block;
    padding: 0 12vw;
  }
}

.grid {
  @include standard-grid;
  width: 100%;
  height: 100vh;
}

.leftColumn {
  border-left: 1px solid transparent-color('lavender', 0.3);
  border-right: 1px solid transparent-color('lavender', 0.3);
  display: flex;
  width: 30vw;
}
