@import '~/styles/utils';

.thumbnailContainer {
  border-radius: 12rem;
  border: 2px solid rgba(216, 218, 236, 0.3);
  aspect-ratio: 1 / 1;
  width: 100%;
  max-width: 256px;
  max-height: 256px;
  overflow: hidden;
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 1;
  background: transparent;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnailOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent-color('black', 0.7);
  color: $color-white;
  opacity: 0;
  transition: 0.3s opacity linear 0.1s;

  svg {
    width: 134rem;
    height: 134rem;
    transform: scale(0.5);
    transition: 0.3s transform ease-in 0s;
  }

  &:hover {
    opacity: 1;
    transition: 0.3s opacity linear 0s;

    svg {
      transform: scale(1);
      transition: 0.3s transform ease-out 0.1s;
    }
  }
}

.caption {
  @include small-text-bold-caps-m;
  display: block;
  color: $color-light-light-gray;
  margin-top: 15rem;

  @include landscape {
    @include small-text-bold-caps-d;
    margin-top: 19.97rem;
  }
}
