@import '~/styles/utils';

.navItemTriggerWrapper {
  position: relative;
}

.navItemTrigger {
  @include body-d;
  appearance: none;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  position: relative;
  transition: color $speed $evil-ease, opacity $speed $evil-ease;
  &:hover {
    color: $color-exo-blue;
  }
  @include landscape {
    font-family: $font-sans-text;
    font-weight: 500;
    font-size: 12rem;
    line-height: 1;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    height: 40rem;
    padding: 0 16rem;
    z-index: 2;
    border-radius: 5rem;
    opacity: 0.45;
    &.isCurrent {
      opacity: 1;
    }
    &:hover {
      color: inherit;
      opacity: 1;
    }
  }
}

.navItemHighlight {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: $color-highlight;
  pointer-events: none;
  border-radius: 5rem;
  display: none;
  @include landscape {
    display: block;
  }
}
