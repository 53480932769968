@import '~/styles/utils';

.container {
  position: relative;
  display: flex;
  background: transparent;
  flex-flow: column nowrap;
  z-index: 5;
}

.gradientContainer {
  --container-height: 160vh;
  height: var(--container-height);
  position: relative;
  width: 100%;

  @include landscape {
    --container-height: 200vh;
  }
}

.gradient {
  width: 100%;
  height: var(--container-height);
  background: linear-gradient(
    180deg,
    rgba(236, 194, 111, 0.5) 0%,
    rgba(110, 153, 234, 1) 65%,
    rgba(8, 11, 27, 1) 100%
  );

  @include landscape {
    background: linear-gradient(
      140deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(236, 194, 111, 1) 33%,
      rgba(110, 153, 234, 1) 66%,
      rgba(8, 11, 27, 1) 100%
    );
  }
}

.solid {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  height: var(--container-height);
  background: $color-dark-dark-blue;
  opacity: 0;
}
