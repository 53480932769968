@import '~/styles/utils';

.canvasContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  * {
    pointer-events: none;
  }
}
