@import '~/styles/utils';

.header {
  width: 100vw;
  height: $header-height;
  padding: 0 $side-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $color-header-background;
  color: $color-foreground;
  position: relative;

  // This blur can't be applied directly or it messes with the blur on the children
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    backdrop-filter: blur(7rem);
  }

  // Replaces the border to prevent issue with the blur
  &:after {
    content: '';
    height: 1px;
    background: transparent-color('lavender', 0.3);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  transition: color $quick $ease-out, background-color $quick $ease-out;
  will-change: color, background-color;

  @include landscape {
    padding-right: calc(
      #{$side-padding} - 16rem
    ); // Compensates for invisible padding around the nav items
  }
}

.logo {
  width: 42rem;

  @include landscape {
    width: 106rem;
  }
}

.mobiNav {
  display: none;
  border-radius: 25%;

  a {
    padding: 0;

    &:hover {
      background: transparent;
    }
  }

  @include md {
    display: block;
  }
}

.cartIcon {
  display: none;
  border-radius: 50%;
  min-width: 10px;

  @media only screen and (min-width: 772px) {
    display: block;
  }

  @include landscape {
    display: block;
  }

  a {
    padding: 0;

    &:hover {
      background: transparent;
    }
  }

  @include sm {
    display: block;
  }
}

.wellstar {
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: $color-white;
  // margin: $side-padding 0;
  padding: 10rem 10rem;
  // box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
  // border: 1px solid $color-white;

  transition: background ease-in-out 0.3s;

  &:hover {
    border: 1px solid $color-exo-blue;
  }

  &.opacityNew {
    background: rgba(255, 255, 255, 0.504);
  }

  @include landscape {
    font-size: 28rem;
    padding: 10rem 10rem;
    text-align: right;
  }
}

.wellstarCta {
  color: $color-black;
}

.closeButton {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: none;
  cursor: pointer;
  padding-top: 28rem;
  transform: translate(50%, -50%);
  width: 30rem;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  z-index: 1;
  margin-right: 15rem;

  svg {
    width: 100%;
  }

  &:hover {
    color: $color-exo-blue;
  }
}
