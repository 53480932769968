@import '~/styles/utils';

.container {
  height: 300vh;
  width: 100%;
  position: relative;
  z-index: 3;
  background: $color-white;
}

.headingContainer {
  height: 240vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  z-index: 2;
  top: 0;
  padding: 0 $side-padding;
  width: 100%;
}

.heading {
  @include title;
  text-align: center;
  position: sticky;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;

  @include landscape {
    max-width: 14ch;
  }
}

.sequenceContainer {
  height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;
}

.canvas {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.outerBottomContainer {
  z-index: 2;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  @include landscape {
    height: 200vh;
  }
}

.bottomContainer {
  padding: 0 $side-padding;
  visibility: false;
  @include sm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40rem;
    height: 86vh;
    align-content: center;
  }

  @include landscape {
    @include standard-grid;
    z-index: 2;
    position: sticky;
    top: 0;
    padding-top: 50rem;
    height: 100vh;
    align-content: start;
  }
}

.copyContainer {
  margin-bottom: 36rem;

  @include landscape {
    padding-top: 15vh;
    grid-column: 1 / 6;
  }

  @include xxl {
    grid-column: 2 / 6;
  }
}

.bottomHeading {
  @include h2-m;
  margin-bottom: 18rem;
}

.bottomCopy {
  @include body;
  margin-bottom: 10rem;
}

.statsGrid {
  display: grid;
  gap: 45rem;
  padding-bottom: 20vh;

  @include landscape {
    padding-top: 15vh;
    grid-column: 8 / 13;
    grid-template-columns: repeat(4, 1fr);
    column-gap: $grid-gap;
  }

  @include xxl {
    grid-column: 8 /12;
  }
}

.statContainer {
  @include landscape {
    grid-column: span 2;
    display: grid;
    grid-template-rows: 120rem auto;
  }
}

.statIcon {
  max-width: 70px;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
}

.statValue {
  @include small-text-bold-caps-m;
  font-size: 12rem;
  margin-bottom: 12rem;
}

.statLabel {
  @include small-text-bold-caps-m;

  @include landscape {
    max-width: 211rem;
  }
}

.bottomIrisContainer {
}
